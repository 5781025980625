/*
 * @Author: Yang Lin
 * @Description: 404相关操作
 * @Date: 2019-12-23 09:12:21
 * @LastEditTime: 2020-06-12 11:10:40
 * @FilePath: \src\views\Page404\model.js
 */

import style from './index.module';

export default {
    data(){
        return {
            style,
            homePath: '/',
            counter: 5,
            timer: null
        };
    },
    mounted(){
        // 倒计时跳转
        this.timer = setInterval(() => {
            if(!(--this.counter)){
                clearInterval(this.timer);
                this.$router.push({
                    path: this.homePath
                });
            }
        },1000);
    },
    beforeDestroy() {
        
        this.timer&&clearInterval(this.timer);
    }
};