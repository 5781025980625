<!--
 * @Author: Yang Lin
 * @Description: 404组件
 * @Date: 2019-12-23 09:12:21
 * @LastEditTime : 2019-12-25 16:19:15
 * @FilePath: \webpack-vue-cli\src\views\Page404\index.vue
 -->

<i18n>
{
    "en-US": {
        "404Tip": "Page not found.",
        "404CounterDown": "After <span>{counter}</span> seconds, automatically jump to the home page."
    },
    "zh-CN": {
        "404Tip": "页面不存在。",
        "404CounterDown": "<span>{counter}</span>秒后自动跳转到首页"
    }
}
</i18n>

<template>
    <div :class="style['page-404']">
        <div :class="style['wrapper']">
            <img src="@/assets/images/404.svg">
            <p :class="style['tips']">{{ $t('404Tip') }}</p>
            <router-link
                :to='homePath'
                :class="style['counter-tips']"
                v-html="$t('404CounterDown',{counter})"
            >
            </router-link>
        </div>
    </div>
</template>

<script>
import model from './model';

export default {
    name: 'ErrorPage',
    mixins: [model]
};
</script>