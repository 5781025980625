/*
 * @Author: Yang Yawen
 * @Description: 项目入口js，vue实例化
 * @Date: 2020-04-13 10:28:06
 * @LastEditTime: 2020-08-11 16:13:06
 * @FilePath: \src\index.js
 */

import '@/utils/auto.polyfill';
import Vue from 'vue';
// 引入html模板
import App from './App';
// 注册路由
import router from './router';
// 注册vuex数据
import store from './store';
// 引入全局样式
import '@/assets/css/common.css';
// 引入Font Awesome样式表
import '@fortawesome/fontawesome-free/css/all.css';
// 引入i18n国际化
import {
    i18n
} from '@/lang/';

// 引入meta标记网页seo信息
import VueMeta from 'vue-meta';
//引入代码统计函数
import track from '@/utils/track';
// 引入element的loading
//引入常用elementui组件
import {
    Loading,
    Row,
    Col,
    Button,
    Popover,
    Tooltip,
    Breadcrumb,
    BreadcrumbItem
} from 'element-ui';
Vue.use(Row);
Vue.use(Col);
Vue.use(Button);
Vue.use(Popover);
Vue.use(Tooltip);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
//  vue-meta 加载页面信息
Vue.use(VueMeta);
// 安装loading指令
Vue.use(Loading.directive);

//安装自定义指令
import directives from './directive' ;

Object.keys(directives).forEach((key)=>{
  Vue.directive(key,directives[key]);
});
//引入过滤器
import vueFilter from './filters';
for (const key in vueFilter) {
    Vue.filter(key, vueFilter[key]);
}

//安装代码统计
track(router);

new Vue({
    el: '#app',
    router,
    store,
    i18n,
    render: h => h(App)
});
