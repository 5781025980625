var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoadingShow
    ? _c(
        "div",
        {
          staticClass: "cngb-loading-spinner",
          class: _vm.style["loading-spinner"]
        },
        [
          _c("div", { class: _vm.style["wrapper"] }, [
            _c("div", { class: _vm.style["circle1"] }),
            _vm._v(" "),
            _c("div", { class: _vm.style["circle2"] }),
            _vm._v(" "),
            _c("div", { class: _vm.style["circle3"] }),
            _vm._v(" "),
            _c("div", { class: _vm.style["circle4"] }),
            _vm._v(" "),
            _c("div", { class: _vm.style["circle5"] }),
            _vm._v(" "),
            _c("div", { class: _vm.style["circle6"] }),
            _vm._v(" "),
            _c("div", { class: _vm.style["circle7"] }),
            _vm._v(" "),
            _c("div", { class: _vm.style["circle8"] }),
            _vm._v(" "),
            _c("div", { class: _vm.style["circle9"] }),
            _vm._v(" "),
            _c("div", { class: _vm.style["circle10"] }),
            _vm._v(" "),
            _c("div", { class: _vm.style["circle11"] }),
            _vm._v(" "),
            _c("div", { class: _vm.style["circle12"] })
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }