/*
 * @Author: Yang Lin
 * @Description: 语言设置、加载
 * @Date: 2020-02-26 14:13:35
 * @LastEditTime: 2020-06-05 14:27:14
 * @FilePath: \src\lang\index.js
 */

/* global LANGUAGE_COOKIE_NAME,APP_COOKIE_DOMAIN,APP_LANGUAGE_COOKIE_AGE,APP_COOKIE_PATH */
/**
 * Those global variables setted in /build/config.js
 * 
 * LANGUAGE_COOKIE_NAME
 * APP_COOKIE_DOMAIN
 * APP_LANGUAGE_COOKIE_AGE
 * APP_COOKIE_PATH
 */

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Cookie from 'js-cookie';
import ElementLocale from 'element-ui/lib/locale/';
Vue.use(VueI18n);

export const LANGS = [{ // 项目允许语种，只有一项则表示单语言
    type: 'English',
    name: 'en-US'
}];
/**该项目只存在英文 */
export const DEFAULT_LOCAL_LANG = 'en-US'; // 项目默认语种，对应LANGS的name属性值

// 语言别名，别名尽量不要重复
const langAlias = {
    'zh-CN': 'zh-hans',
    'en-US': 'en'
};

/**
 * 验证语言名称是否是正确的
 * 
 * @param {String} lang ; 被验证的语言名字
 * 
 * @returns {Boolean|String} ; 如果语言正确返回语言，错误返回false
 */
function verifyLang(lang){
    if(!lang || typeof lang !== 'string'){
        return false;
    }
    const clang = lang.toLowerCase().replace(/-[a-z]+/g,match => match.toUpperCase()); // 转换被检测语言名称为（xx-XXX-XXX）风格
    for(let len = LANGS.length;len--;){
        const {
            name
        } = LANGS[len];
        const alias = langAlias[name];
        const aliasArr = Array.isArray(alias) ? alias : [alias];
        if(
            name === lang // 名称合法
            || name === clang // 名称转换后合法
            || aliasArr.includes(lang) // 名称别名合法
            || aliasArr.includes(clang) // 名称转换后合法
        ){
            return name;
        }
    }

    return false;
}

/**
 * 获取当前应该设置的语言
 * 
 * @returns {String} ; 获取要返回的语言名称
 */
function getActiveLang(){
    if(LANGS.length < 2){ // 单语言项目
        return DEFAULT_LOCAL_LANG;
    }

    const cookieLang = Cookie.get(LANGUAGE_COOKIE_NAME); // cookie 存储lang值
    let tempLangVal;
    /* eslint-disable */
    if(tempLangVal = verifyLang(cookieLang)){
    /* eslint-enable */
        return tempLangVal;
    }

    const navigator = window.navigator;
    const browserLang = navigator.language ? navigator.language : navigator.userLanguage; // 浏览器环境语言
    /* eslint-disable */
    if(tempLangVal = verifyLang(browserLang)){
    /* eslint-enable */
        return tempLangVal;
    }

    return DEFAULT_LOCAL_LANG; // 默认语言
}

export const i18n = new VueI18n({ 
    locale: getActiveLang(),
    fallbackLocale: getActiveLang(),
    silentTranslationWarn: true
});
ElementLocale.i18n((key,value) => i18n.t(key,value)); // element 国际化加载

const loadedLanguages = []; // 已经加载过的语言

/**
 * 修改i18n的语言
 * 
 * @param {String} lang; 要设置的语言 
 */
function setI18nLanguage(lang){
    i18n.locale = lang;
    let cookieLangVal = langAlias[lang];
    cookieLangVal = cookieLangVal 
        ? Array.isArray(cookieLangVal)
            ? cookieLangVal[0]
            : cookieLangVal
        : cookieLangVal;
        
    setLangCookie(cookieLangVal);
    return lang;
}

/**
 * 设置cookie缓存语言
 * 
 * @param {String} lang ; 要设置cookie的语言
 */
function setLangCookie(lang){
    Cookie.set(LANGUAGE_COOKIE_NAME,lang,{
        domain: APP_COOKIE_DOMAIN,
        expires: parseInt(APP_LANGUAGE_COOKIE_AGE),
        path: APP_COOKIE_PATH
    });
}
/**
 * 按需动态加载语言包
 * 
 * @param {String} lang; 要加载的语言
 * 
 * @returns {Promise};
 */
export function loadLanguageAsync(lang){
    setLangCookie(lang);
    if(i18n.lang !== lang){
        if(!loadedLanguages.includes(lang)){
            return import(/* webpackChunkName: "lang-[request]" */`./${lang}`).then(msgs => {
                i18n.setLocaleMessage(lang,msgs.default);
                loadedLanguages.push(lang);
                return setI18nLanguage(lang);
            });
        }
        return Promise.resolve(setI18nLanguage(lang));
    }

    return Promise.resolve(lang);
}

loadLanguageAsync(getActiveLang()); // 动态加载语言包