/*
 * @Author: Yang Lin
 * @Description: 工具函数库
 * @Date: 2019-11-28 13:53:04
 * @LastEditTime : 2019-12-25 10:12:47
 * @FilePath: \webpack-vue-cli\src\utils\tool.js
 */

/**
 * 获取变量实际的类型
 * 
 * @param {Any} target; 要获取类型的变量
 * 
 * @returns {String}; 返回变量类型字符串
 */
export function getType(target){
    // 基类型 直接返回
    const type = typeof target;
    if( type !== 'object' ){
        return type;
    }
    // object 类型
    const objectType = Object.prototype.toString.call(target);
    switch(objectType){
        case '[object Object]':
            return 'object';
        case '[object Null]':
            return 'null';
        case '[object Function]':
            return 'function';
        case '[object Array]':
            return 'array';
        case '[object Date]':
            return 'date';
        case '[object RegExp]':
            return 'regexp';
        case '[object Math]':
            return 'math';
        case '[object Error]':
            return 'error';
        case '[object ArrayBuffer]':
            return 'arraybuffer';
        case '[object DataView]':
            return 'dataview';
        case '[object Set]':
            return 'set';
        case '[object WeakSet]':
            return 'weakset';
        case '[object Map]':
            return 'map';
        case '[object WeakMap]':
            return 'weakmap';
        default:
            return 'unknown';
    }
}

/**
 * 函数节流
 * 
 * @param {Function} fn; 节流函数
 * @param {Number} [delay=500]; 节流时间阀，单位：毫秒
 * @param {Object} [options]; 节流参数
 * @param {Boolean} options.leading = true; 立即执行
 * @param {Boolean} options.trailing = false; 最后一次是否执行
 * 
 * @returns {Function}; 返回已经节流的函数，该函数的cancel函数可取消节流
 */

export function throttle(
    fn,
    delay = 500,
    options = {
        leading: true,
        trailing: false
    }
){
    let start = 0;
    let result;
    const {
        leading,
        trailing
    } = options;
    let timer;

    const throttled = function(){
        const now = +new Date();
        const context = this;
        const args = arguments;

        if(start === 0 && leading === false){ // 非立即执行
            start = now;
        }

        const remaining = delay - (now - start);
        if(remaining <= 0 || remaining > delay){ // 过了节流时间阀
            if(timer){ // 清除节流时间内的定时任务
                clearTimeout(timer);
                timer = null;
            }
            start = now;
            result = fn.apply(context,args);
        }else if(!timer && !!trailing){ // 没过时间阀，如果没有延迟任务，则添加
            timer = setTimeout(() => {
                start = leading === false ? 0 :  +new Date();
                timer = null;
                result = fn.apply(context,args);
            },remaining);
        }else{
            throw new TypeError('The options of leading or trailing can not both false.');
        }

        return result;
    };
    throttled.cancel = function(){ // 取消当前节流
        clearTimeout(timer);
        timer = null;
        start = 0;
    };

    return throttled;
}

/**
 * 防抖函数
 * 
 * @param {Function} fn; 防止重复回调函数
 * @param {Number} [delay=500]; 防抖延迟时间 ，单位：毫秒
 * @param {Boolean} [immediate = false]; 是否立即执行
 * 
 * @returns {Function}; 返回经过防抖处理的函数，该函数的cancel函数可取消防抖
 */

export function debounce(fn,delay = 500,immediate = false){
    let timer;
    let result;
    const debounced = function(){
        const context = this;
        const args = arguments;

        timer && clearTimeout(timer); // 取消延迟执行，保证只执行最后一次

        if(immediate){ // 立即执行，延迟delay后可重新执行，类似节流
            const callNow = !timer;
            timer = setTimeout(() => {
                timer = null;
            },delay);
            if(callNow){
                result = fn.apply(context,args);
            }
        }else{ // 延迟执行
            timer = setTimeout(() => {
                timer = null;
                fn.apply(context,args);
            },delay);
        }

        return result;
    };

    debounced.cancel = function(){ // 取消防抖
        clearTimeout(timer);
        timer = null;
    };

    return debounced;
}