/*
 * @Author: Yang Yawen
 * @Description: 路由注册页面
 * @Date: 2019-11-06 16:20:18
 * @LastEditTime: 2020-08-06 15:21:02
 * @FilePath: \src\router.js
 */

/* global APP_BASE_PATH */
/**
 * APP_BASE_PATH defined in /build/configs/global.vars.js
 */

import Vue from 'vue';
import Router from 'vue-router';
import {
    loadAsyncComponent,
    loginRedirect
} from '@/utils/router';
import store from './store';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: APP_BASE_PATH,
    routes: [{
        path: '', // base布局
        component(){
            return loadAsyncComponent(import(/* webpackChunkName: "base.layout" */'@/layouts/Base'));
        },
        children: [{ //
            path: '',
            name: 'home',
            component(){
                return loadAsyncComponent(import(/* webpackChunkName: "home" */'@/views/Home'));
            },
            meta: {
                loginRequired: false
            }
        },{
            path: 'project', // project页面
            name: 'project',
            component(){
                return loadAsyncComponent(import(/* webpackChunkName: "blank" */'@/layouts/Blank'));
            },
            children: [{
                    path: '',
                    name: 'projectHome',
                    component(){
                        return loadAsyncComponent(import(/* webpackChunkName: "project" */'@/views/Project/ProjectHome'));
                    },
                    meta: {
                        loginRequired: false
                    }
                },{
                    path: ':name',
                    name: 'projectDetail',
                    component(){
                        return loadAsyncComponent(import(/* webpackChunkName: "projectDetail" */'@/views/Project/ProjectDetail'));
                    },
                    meta: {
                        loginRequired: false
                    }
                }]
        },{
            path: 'species', // species 页面
            name: 'species',
            component(){
                return loadAsyncComponent(import(/* webpackChunkName: "species" */'@/views/Species'));
            },
            meta: {
                loginRequired: false
            }
        },{
            path: 'samples', // samples 页面
            name: 'samples',
            component(){
                return loadAsyncComponent(import(/* webpackChunkName: "samples" */'@/layouts/Blank'));
            },
            children: [{
                    path: '',
                    name: 'samplesHome',
                    component(){
                        return loadAsyncComponent(import(/* webpackChunkName: "literature" */'@/views/Sample/SampleHome'));
                    },
                    meta: {
                        loginRequired: false
                    }
                },{
                    path: ':id',
                    name: 'samplesDetail',
                    component(){
                        return loadAsyncComponent(import(/* webpackChunkName: "literatureDetail" */'@/views/Sample/SampleDetail'));
                    },
                    meta: {
                        loginRequired: false
                    }
                }]
        },{
            path: 'literature', // literature 页面
            name: 'literature',
            component(){
                return loadAsyncComponent(import(/* webpackChunkName: "blank" */'@/layouts/Blank'));
            },
            children: [{
                    path: '',
                    name: 'literatureHome',
                    component(){
                        return loadAsyncComponent(import(/* webpackChunkName: "literature" */'@/views/Literature/LiteratureHome'));
                    },
                    meta: {
                        loginRequired: false
                    }
                },{
                    path: ':id',
                    name: 'literatureDetail',
                    component(){
                        return loadAsyncComponent(import(/* webpackChunkName: "literatureDetail" */'@/views/Literature/LiteratureDetail'));
                    },
                    meta: {
                        loginRequired: false
                    }
                }]
        },{
            path: 'download', // download 页面
            name: 'download',
            component(){
                return loadAsyncComponent(import(/* webpackChunkName: "download" */'@/views/Page404'));
            }
        },{
            path: 'blast', // blast 页面
            name: 'blast',
            component(){
                return loadAsyncComponent(import(/* webpackChunkName: "blast" */'@/views/Page404'));
            }
        },{
            path: '*', // 404 页面
            name: '404',
            component(){
                return loadAsyncComponent(import(/* webpackChunkName: "404" */'@/views/Page404'));
            }
        }]
    }]
});

/**
 * 路由前置守卫，登录验证
 */
router.beforeEach((to,from,next) => {
    const {
        meta: {
            loginRequired
        }
    } = to;
    store.dispatch('checkLogin',loginRequired)
        .then(() => {
            next(); // 登录成功或不需要登录
        },relogin => {
            relogin === true && loginRedirect(to.fullPath); //需要重新登录
        });
    next();
});

export default router;