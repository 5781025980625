/*
 * @Author: Yang Yawen
 * @Description: 过滤器
 * @Date: 2020-06-24 14:38:00
 * @LastEditTime: 2020-08-28 16:38:44
 * @FilePath: \src\filters\index.js
 */ 
const Filter = {
    nullFilter: value => {
        return value!=""?value:"--";
    },
    linkFilter: (value,name,id) => {
        switch (value) {
            case 'project':
                return '/project/'+name
                break;
        
            case 'species':
                return '/species?id='+id
                break;
        
            case 'literature':
                return '/literature/'+name
                break;
        
            case 'library_id':
                return '/samples/'+name
                break;
        
            case 'flowcell_lane':
                return '/samples'+'?search='+name
                break;
        
            default:
                break;
        }
    },
    strFilter: (value,len) => {
        return  value.substring(0,len)+"...";
    },
    encodes: (value) => {
        return value.indexOf('/')>0? '/literature/'+ value.replace('/','=') : '/literature/'+ value;
    }
};
export default Filter;
