<!--
 * @Author: Yang Lin
 * @Description: Loading 组件模块
 * @Date: 2020-04-13 15:37:39
 * @LastEditTime: 2020-04-13 15:38:55
 * @FilePath: \vue-webpack-cli\src\components\Loading\index.vue
 -->
 
<template>
    <div 
        :class="style['loading-spinner']"
        class="cngb-loading-spinner"
        v-if="isLoadingShow"
    >
        <div :class="style['wrapper']">
            <div :class="style['circle1']"></div>
            <div :class="style['circle2']"></div>
            <div :class="style['circle3']"></div>
            <div :class="style['circle4']"></div>
            <div :class="style['circle5']"></div>
            <div :class="style['circle6']"></div>
            <div :class="style['circle7']"></div>
            <div :class="style['circle8']"></div>
            <div :class="style['circle9']"></div>
            <div :class="style['circle10']"></div>
            <div :class="style['circle11']"></div>
            <div :class="style['circle12']"></div>
        </div>
    </div>
</template>

<script>
import model from './model';

export default {
    name: 'Loading',
    mixins: [model]
};
</script>
