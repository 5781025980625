/*
 * @Author: Yang Yawen
 * @Description: 简介
 * @Date: 2020-05-18 17:13:19
 * @LastEditTime: 2020-06-12 11:06:49
 * @FilePath: \src\views\Home\store.js
 */ 
//import Axios from "axios";

/* 
 * vuex数据
 */


const state = {
};

const getters = {};

const mutations = {
};

const actions = {
   
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};