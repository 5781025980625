var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.style["page-404"] }, [
    _c(
      "div",
      { class: _vm.style["wrapper"] },
      [
        _c("img", { attrs: { src: require("@/assets/images/404.svg") } }),
        _vm._v(" "),
        _c("p", { class: _vm.style["tips"] }, [
          _vm._v(_vm._s(_vm.$t("404Tip")))
        ]),
        _vm._v(" "),
        _c("router-link", {
          class: _vm.style["counter-tips"],
          attrs: { to: _vm.homePath },
          domProps: {
            innerHTML: _vm._s(
              _vm.$t("404CounterDown", { counter: _vm.counter })
            )
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }