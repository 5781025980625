/*
 * @Author: Yang Lin
 * @Description: 账户相关数据store
 * @Date: 2020-04-13 10:28:06
 * @LastEditTime: 2020-08-28 16:42:15
 * @FilePath: \src\store\modules\account.js
 */

import request from '@/utils/request';
import {
    getType
} from '@/utils/tool';

const defaultAccountData = {
    isAuthenticated: false, // 是否认证
    role: '', //
    displayUsername: '', // 用户名称
    isUpdated: false, // 是否更新
    hasEmail: false, // mail注册
    hasPhone: false, // 手机号注册
    hasPassword: false // 密码注册
};

const state = {
    accountData: {
        ...defaultAccountData
    }
};

/**
 * mutations 下函数参数：
 *
 * @param {Object} state; 局部模块状态
 * @param {Any} payload; 荷载数据
 */
const mutations = {
    /**
     * 更新登录账户
     */
    updateAccountData(state,payload){
        if(getType(payload) !== 'object'){
            throw new TypeError('Account info must be plain object.');
        }
        // const {
        //     is_authenticated: isAuthenticated,
        //     role,
        //     display_username: displayUsername,
        //     email: hasEmail,
        //     phone: hasPhone,
        //     password: hasPassword
        // } = payload;

        //更新账户信息
        state.accountData = {
            ...state.accountData,
            ...{
                isAuthenticated: payload.id !== '',
                role: payload.account_type,
                displayUsername: payload.display_name,
                isUpdated: true,
                hasEmail: payload.display_name.indexOf('@') > -1,
                hasPhone: payload.display_name.indexOf('+') > -1,
                hasPassword: true
            }
        };
    },
    /**
     * 登出初始化账号store
     */
    logout(state){
        state.accountData = {
            ...state.accountData,
            ...{
                isAuthenticated: false,
                role: '',
                displayUsername: '',
                isUpdated: true
            }
        };
    }
};

/**
 * actions 下函数参数：
 *
 * @param {Object} context; 类似store实例对象
 * @param {Object} context.state; store局部状态
 * @param {Obejct} context.rootState; store全局状态
 * @param {Function} context.commit; commit方法,提交mutations
 * @param {Function} context.dispatch; dispatch方法，分发actions
 * @param {Object} context.getter; 局部getters
 * @param {Object} context.rootGetters; 全局getters
 * @param {Any} payload; 荷载数据
 */
const actions = {
    /**
     * 登录验证
     */
    async checkLogin({ commit, state}, loginRequired){
        /**
         * 根据当前域名修改session请求路径
         */
        let api_host;
        switch (location.host) {
            case "1kitedev.cngb.org":
                api_host="https://dbdev.cngb.org/account/api/session/get_current_session/";
                break;
            case "1kitetest.cngb.org":
                api_host="https://dbtest.cngb.org/account/api/session/get_current_session/";
                break;
            case "1kiterc.cngb.org":
                api_host="https://dbrc.cngb.org/account/api/session/get_current_session/";
                break;
            case "1kite.cngb.org":
                api_host="https://db.cngb.org/account/api/session/get_current_session/";
                break;
            default:
                api_host="https://db.cngb.org/account/api/session/get_current_session/";
        }
        try{

            const data = await request.get(api_host, {withCredentials: true}); // 请求后端返回账号信息
            // 更新则更新登录状态
            commit('updateAccountData',data);

            // const {
            //     ['is_authenticated']: isAuthenticated,
            //     ['display_username']: displayUsername
            // } = data;
            const isAuthenticated = data.id !== '';
            const displayUsername = data.display_name;

            if(
                !!loginRequired
                && (
                    !isAuthenticated
                    || displayUsername === ''
                    || typeof displayUsername === 'undefined'
                    || displayUsername === null
                )
            ){ //需要登录且无登录状态
                return Promise.reject(true);
            }

            return Promise.resolve(state.accountData);

        }catch(e){
            console.log(e);
        }
    }
};

export default {
    state,
    mutations,
    actions
};
