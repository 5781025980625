/*
 * @Author: Yang Lin
 * @Description: store注册表
 * @Date: 2019-11-22 11:13:47
 * @LastEditTime: 2020-08-06 14:20:11
 * @FilePath: \src\store\index.js
 */

import Vue from 'vue';
import Vuex from 'vuex';
import account from './modules/account';
import home from '@/views/Home/store';

Vue.use(Vuex);

/**
 * vue store 注册
 */

export default new Vuex.Store({
    state: {
        showLoading: true
    },
    mutations: {
        updateLoading(state, data) {
            console.log(state.showLoading,data);
            state.showLoading= data;
        }
    },
    modules: {
        account,
        home
    },
    strict: true
});