/*
 * @Author: Yang Lin
 * @Description: 流量统计追踪
 * @Date: 2020-04-17 15:26:25
 * @LastEditTime: 2020-04-17 15:53:55
 * @FilePath: \vue-webpack-cli\src\utils\track.js
 */

/* global APP_GOOGLE_ANALYTICS_ID, APP_BAIDU_ANALYTICS_ID, APP_BASE_PATH, MODE */
/**
 * 上述全局变量定义于/build/configs/global.vars.js
 */

import VueRouter from 'vue-router';
import Vue from 'vue';
import {
    getType
} from './tool';
// 引入google分析，流量统计
import VueGtag from 'vue-gtag';
// 引入baidu分析，流量统计
import VueBaiduAnalytics from 'vue-baidu-analytics';

// google分析默认配置
let defaultGoogleOptions = {
    config: {
        id: APP_GOOGLE_ANALYTICS_ID
    },
    pageTrackerTemplate(to){
        return {
            ['page_title']: document.title,
            ['page_path']: `${APP_BASE_PATH}${to.fullPath}`
        };
    }
};

//百度分析默认配置
let defaultBauduOptions = {
    siteIdList: [APP_BAIDU_ANALYTICS_ID],
    debug: MODE === 'development'
};

/**
 * 安装代码统计
 * 
 * @param {Object} router; 路由实例
 * @param {Object} [options]; 配置参数
 * @param {Object} options.google; google统计代码配置参数
 * @param {Object} options.baidu; baidu统计代码配置参数
 */
export default (router,options) => {
    if(!(router instanceof VueRouter)){
        throw new TypeError('第一个参数必须是vue router实例');
    }
 
    if(getType(options) === 'object'){
        const {
            google,
            baidu
        } = options;
        defaultGoogleOptions = {
            ...defaultGoogleOptions,
            ...google
        };
        defaultBauduOptions = {
            ...defaultBauduOptions,
            ...baidu
        };
    }

    Vue.use(VueGtag,defaultGoogleOptions,router);
    Vue.use(VueBaiduAnalytics,{
        router,
        ...defaultBauduOptions
    });
};