/*
 * @Author: Yang Yawen
 * @Description: 数字从0增长指令
 * @Date: 2020-06-04 10:18:35
 * @LastEditTime: 2020-06-29 10:14:27
 * @FilePath: \src\directive\index.js
 */ 

export default{
    countRunning: {
        inserted: function (el, binding, vnode) {
            var timer = null;
            const targetDomCount = el.getAttribute('data-target'); //data-target为目标值
            let nowCount = 0; //当前页面显示值
            timer = setInterval(() => {
                if (nowCount < targetDomCount) {
                    nowCount+=Math.ceil(targetDomCount-nowCount)/2;
                    el.innerHTML = nowCount;
                    console.log(nowCount);
                } else {
                    console.log(nowCount);
                    el.innerHTML=targetDomCount;
                    clearInterval(timer);
                    timer = null;
                }
            }, 8);
        }
    }
};
