/*
 * @Author: Yang Lin
 * @Description: axios请求api封装
 * @Date: 2020-02-26 14:13:35
 * @LastEditTime: 2020-05-25 14:41:41
 * @FilePath: \src\utils\request.js
 */


/* global LANGUAGE_COOKIE_NAME APP_CSRF_COOKIE_NAME */
/**
 * 以下变量定义于/build/configs/global.vars.js中
 * LANGUAGE_COOKIE_NAME
 * APP_CSRF_COOKIE_NAME
 */

import axios from 'axios';
import qs from 'qs'; // axios自带工具
import {
    Message as message
} from 'element-ui';
import {
    getType
} from '@/utils/tool';
import Cookie from 'js-cookie';

/**
 * post或put方法的content-type三种用法：
 * 
 *  1. Content-Type: application/json
 *      post使用为：axios.post(url,{a:1,b:2})
 *  2. Content-Type: application/x-www-form-urlencoded
 *      post使用为：axios.post(url,qs.stringify({a:1,b:2}))
 *  3. Content-Type: multipart/form-data
 *      post使用为：let data = new FormData(); data.append('a',1'); data.append('b',2); axios.post(url,data)
 */

const api = axios.create({
    timeout: 10000, // 超时时间10秒
    headers: {
        post: { // 设置post的 content-type
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
});

// 后端返回错误码对应错误提示信息
const langs = {
    'zh-hans': {
        networkError: '网络错误，请稍后重试',
        '2001': '非法的请求参数',
        '2002': '未授权的操作',
        '2003': '指定属性不存在',
        '2004': '指定资源（页面）不存在',
        '2005': '禁止操作',
        '2006': '非法的请求方法',
        '2007': '表单验证未通过 ',
        '2008': '服务器内部错误',
        '2009': '从数据库获取数据报错',
        '2010': '第三方接口响应异常',
        '2011': '第三方接口数据获取异常（第三方接口响应正常，但未获取到需要数据）',
        '2012': '文件不存在',
        '2013': '	无权限操作文件',
        '2014': 'es查询异常'
    },
    en: {
        networkError: 'Network error',
        '2001': 'Invalid request parameter.',
        '2002': 'Unauthorized operation.',
        '2003': 'Request attribution does not exist.',
        '2004': 'Request resource does not exist.',
        '2005': 'Forbidden.',
        '2006': 'Invalid request method.',
        '2007': 'Validate form failed.',
        '2008': 'Internal server error.',
        '2009': 'Get data from database failed.',
        '2010': 'Third-party interface responds abnormally.',
        '2011': 'Third-party interface data acquisition exception',
        '2012': 'File does not exist.',
        '2013': 'Operation file is not authorized.',
        '2014': 'ES query exception.'
    }
};

/**
 * 根据错误码弹出提示信息
 * @param {Number|String} code; 错误码
 * @param {String(success|warning|info|error)} [type=error]; 信息弹窗类型,默认为error
 */
function showMessage(code,type = 'error'){
    const curCode = getType(code) === 'array'
        ? code[0]
        : getType(code) === 'object'
            ? code.code
            : code;
    const cookieLang = Cookie.get(LANGUAGE_COOKIE_NAME); // cookie 存储lang值
    const curLangObj = langs[cookieLang]; // 根据cookie语言类型获取对应的提示信息
    message({
        message: curLangObj[curCode] || curLangObj.networkError,
        type: type
    });
}

/**
 * 根据http的post/put方法的content-type处理axios的data参数
 * 
 * @param {Object} data ; 要被转换的数据
 * @param {String} contentType ; post/put方法的content-type
 * 
 * @returns {Object|String}
 */
function transformData(contentType,data = {}){
    if(getType(data) === 'null'){
        data = {};
    }
    if(getType(data) !== 'object'){
        throw new TypeError('Axios post data must be object.');
    }
    data = Object.assign({
        csrfmiddlewaretoken: Cookie.get(APP_CSRF_COOKIE_NAME)
    },data);
    switch (contentType){
        case 'application/x-www-form-urlencoded':
            try {
                return qs.stringify(data); // 将对象序列化成url形式
            } catch (error) {
                throw new TypeError('Axios post data must be object.');
            }
        case 'multipart/form-data': { //此方法仅支持IE10+
            const keys = Object.keys(data);

            return keys.reduce((acc,key) => {
                acc.append(key,data[key]);
                return acc;
            },new FormData()); // 生成formdata格式对象
        }
        default:
            return data;
    }
    
}

/**
 * 请求拦截，处理请求参数
 */
api.interceptors.request.use(
    config => {
        const {
            data,
            method,
            headers: {
                post: {
                    'Content-Type': postContentType
                },
                put: {
                    'Content-Type': putContentType
                },
                patch: {
                    'Content-Type': patchContentType
                },
                delete: {
                    'Content-Type': deleteContentType
                }
            }
        } = config;
        const methodObj = {
            put: putContentType,
            post: postContentType,
            patch: patchContentType,
            delete: deleteContentType
        };

        // 如果是put、post、patch、delete方法，处理data数据
        
        method in methodObj && ( config.data = transformData(methodObj[method],data));
        
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

/**
 * 响应拦截，处理返回结果
 */
api.interceptors.response.use(
    response => {
        
        const {
            data: {
                code,
                data,
                error,
                meta
            },
            status
        } = response;
        
        if(code == 0||status == 200){
            if(typeof(meta) != 'undefined' ){
                return Promise.resolve({data,meta});
            }else{
                return Promise.resolve(data);
            }
        }else{
            showMessage(error);
            return Promise.reject(response);
        }
    },
    error => {
        const {
            response
        } = error;
        if(response){ // 请求以发出，但是不在2xx范围
            showMessage(0);
            return Promise.reject(response);
        }else{ // 断网状态
            return Promise.reject(error);
        }
    }
);

export default api;