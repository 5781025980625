/*
 * @Author: Yang Lin
 * @Description: Loading 组件逻辑
 * @Date: 2020-04-13 15:37:39
 * @LastEditTime: 2020-04-13 15:39:09
 * @FilePath: \vue-webpack-cli\src\components\Loading\model.js
 */

import style from './index.module';

export default {
    data(){
        return {
            style,
            isLoadingShow: this.visible // loading内部变量，控制显隐
        };
    },
    props: {
        visible: { // loading显隐
            type: Boolean,
            default: false
        }
    },
    watch: {
        visible(newVal){ // 设置loading传入变量显隐
            this.isLoadingShow = newVal;
        }
    },
    destroyed(){ // 销毁阶段隐藏loading
        this.isLoadingShow = false;
    }
};