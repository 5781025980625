/*
 * @Author: Yang Lin
 * @Description: 路由页面相关操作函数
 * @Date: 2019-11-25 09:07:11
 * @LastEditTime: 2020-03-07 19:28:26
 * @FilePath: \virus_desktop_vue\src\utils\router.js
 */

/* global APP_BASE_PATH,APP_ACCOUNT_BASEURL */
/**
 * 以下变量定义于/build/configs/global.vars.js中
 * APP_BASE_PATH
 * APP_ACCOUNT_BASEURL
 */

import Loading from '@/components/Loading';
import ErrorPage from '@/views/Page404';

/*
* 异步加载组件时，处理中间过渡的函数 
* @params {Object},需要加载的组件,例如import('@/views/Home/index')
* @return {Object}, 返回一个Promise对象
*/
export function loadAsyncComponent(asyncView){
    const asyncHandler = () => ({
        component: asyncView,
        loading: Loading,
        error: ErrorPage,
        delay: 200,
        timeout: 3000
    });

    return Promise.resolve({
        functional: true,
        render(h,{data,children}){
            return h(asyncHandler,data,children);
        }
    });
}

/**
 * 登录验证跳转
 * 
 * @param {String} nextPath = ''; 相对路由路径，默认为首页
 * @param {Boolean} historyStore = false; 是否保存记录跳转，默认不保存 
 */
export function loginRedirect(
    nextPath = '',
    historyStore = false
){
    const originPath = APP_BASE_PATH.trim().replace(/[(^/)(/$)]/g,'');
    const path = originPath ? `/${originPath}` : '';
    const toPath = nextPath.indexOf('/') === 0 ? nextPath.substr(1) : nextPath;
    const nextURI = encodeURIComponent(`${location.origin}${path}/${toPath}`);
    const redirectURI = `${APP_ACCOUNT_BASEURL}/account/login/?next=${nextURI}`;
    
    if(historyStore){
        location.href = redirectURI;
    }else{
        location.replace(redirectURI);
    }
}